<template>
    <div class="drop-down">
        <div class="question-container" @click="toggleDropDown">
            <h1 class="question">{{ question }}</h1>
            <img src="@/components/assets/FAQ/arrow.svg" alt="arrow" :class="isOpen ? 'rotate-arrow' : ''">
        </div>
        <component :is="content" v-if="isOpen" />
    </div>
</template>

<script>
export default {
    props: {
        question: String,
        content: Object
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleDropDown() {
            this.isOpen = !this.isOpen
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.drop-down {
    padding: 16px 0;
    border-bottom: 1px solid #FFFFFF14;
}

.question {
    font-size: 15px;
    font-weight: 600;
}

.answer {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.rotate-arrow {
    transform: rotate(180deg);
}
</style>
